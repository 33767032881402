require("./bootstrap");

import Alpine from "alpinejs";
import intlTelInput from "intl-tel-input";
import { Loader } from "@googlemaps/js-api-loader";
import Tooltip from "@ryangjchandler/alpine-tooltip";

Alpine.plugin(Tooltip);

window.intlTelInput = intlTelInput;
// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

window.Loader = Loader;
window.Alpine = Alpine;

Alpine.start();
